<template>
  <v-form
    ref="form"
    lazy-validation
  >
    <v-card class="alert-form">
      <!-- :loading="isLoading" -->
      <v-card-title class="d-flex justify-space-between">
        <span>
          {{ $t(type) }}
        </span>
        <v-btn
          v-if="$admin.can('alerts.delete') && type == 'edit'"
          color="danger"
          x-small
          @click="deleteRow"
        >
          Delete
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container class="alertFormContainer">
          <!-- name, tenant , Vehicel -->
          <span class="note-text">Alert Name</span>

          <v-row>
            <v-col
              cols="12"
              sm="8"
            >
              <v-text-field
                v-model="item.name"
                dense
                :rules="[rules.required, rules.max_length(30)]"
              />
              <!-- :label="$t('alert_name_*')" -->
            </v-col>
          </v-row>
          <span class="note-text">Select Vehicles</span>
          <v-row>
            <v-col
              v-if="authUser.isAdmin"
              cols="12"
              sm="4"
            >
              <div class="d-flex align-baseline gap5">
                <v-select
                  v-model="item.tenant"
                  dense
                  :loading="tenantLoading"
                  :items="tenantsFiltersList"
                  item-text="name"
                  item-value="id"
                  :label="$t('select_tenant_*')"
                  :rules="[rules.required]"
                  @change="deleteVehicle"
                />
              </div>
            </v-col>
            <v-col
              cols="12"
              sm="4"
            >
              <div class="d-flex align-baseline gap5">
                <v-select
                  v-model="vehiclesCheckbox"
                  dense
                  :loading="vehicleLoading"
                  :items="vehicleList"
                  item-text="vehicle_name"
                  item-value="id"
                  :label="$t('select_vehicle_*')"
                  multiple
                  :rules="getRuleValidation() ? ['Required'] : []"
                  @change="fieldSelected"
                  @blur="fieldSelectedVehicle"
                >
                  <template
                    v-if="vehicleList.length > 0"
                    v-slot:prepend-item
                  >
                    <v-checkbox
                      v-model="selectAllVehicles"
                      class="selected allCheckBox"
                      label="All"
                      dense
                      @change="toggleSelectAllVehicles"
                    />
                  </template>
                  <template v-slot:selection="data">
                    <span
                      v-if="data.index === 0"
                      class="selected selectedBox"
                    >
                      <span>{{ data.item.vehicle_name }}</span>
                    </span>
                    <span
                      v-if="data.index === 1"
                      class="grey--text text-caption"
                    >
                      (+{{ vehiclesCheckbox.length - 1 }} others)
                    </span>
                  </template>
                </v-select>
              </div>
            </v-col>
          </v-row>
          <!-- name, tenant , Vehicel -->
          <!-- Time -->
          <span class="note-text">Time Duration</span>
          <v-row>
            <v-col
              cols="12"
              sm="3"
            >
              <div class="d-flex align-baseline gap5">
                <v-select
                  v-model="item.monitoring_time_type"
                  dense
                  :items="timeOptions"
                  item-value="id"
                  item-text="name"
                  :label="$t('monitoring_time_*')"
                  :rules="[rules.required]"
                  @change="onTimeChange"
                />
              </div>
            </v-col>
            <v-col
              cols="12"
              sm="3"
            >
              <div class="d-flex align-baseline gap5">
                <v-text-field
                  v-model="item.monitoring_start_time"
                  dense
                  :disabled="item.monitoring_time_type == '24/7' ||
                    !item.monitoring_time_type
                  "
                  type="time"
                  name="time_window_start"
                  :label="$t('monitoring_start_time_*')"
                  :rules="[rules.required]"
                />
              </div>
            </v-col>
            <v-col
              cols="12"
              sm="3"
            >
              <v-text-field
                v-model="item.monitoring_end_time"
                dense
                :disabled="item.monitoring_time_type == '24/7' ||
                  !item.monitoring_time_type
                "
                type="time"
                name="time_window_start"
                :label="$t('monitoring_end_time_*')"
                :rules="[rules.required, checkTime(item.monitoring_start_time)]"
              />
            </v-col>
          </v-row>
          <!-- Time -->
          <!-- Condition -->
          <span class="note-text">Parameters</span>
          <v-row
            v-for="(r, i) in item.alert_conditions"
            :key="i"
          >
            <v-col
              cols="6"
              sm="3"
            >
              <div class="d-flex align-baseline gap5">
                <v-autocomplete
                  v-model="r.md_alert_metric"
                  dense
                  :items="metrics"
                  item-value="id"
                  item-text="name"
                  :label="$t('field_*')"
                  :rules="[rules.required]"
                  @change="() => delete r.value"
                />
              </div>
            </v-col>
            <v-col
              cols="6"
              sm="3"
            >
              <!-- :items="
                  getValueType(r.md_alert_metric)?.data_type == 'boolean'
                    ? operatorsBoolean
                    : operators
                " -->
              <v-select
                v-model="r.operator"
                dense
                :items="getOperators(r.md_alert_metric)"
                item-value="id"
                item-text="name"
                :label="$t('condition_*')"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col
              cols="6"
              sm="3"
            >
              <v-select
                v-if="getValueType(r.md_alert_metric)?.data_type == 'boolean'"
                v-model="r.value"
                dense
                :items="['true', 'false']"
                :label="`${$t('value')} ${getValueType(r.md_alert_metric)?.unit || ''
                } *`"
                :rules="[
                  rules.required,
                  checkDataType(getValueType(r.md_alert_metric)),
                  checkByOperator(r.operator),
                ]"
              />
              <v-text-field
                v-else
                v-model="r.value"
                dense
                :label="`${$t('value')} ${getValueType(r.md_alert_metric)?.unit || ''
                } *`"
                :rules="[
                  rules.required,
                  checkDataType(getValueType(r.md_alert_metric)),
                  checkByOperator(r.operator, getValueType(r.md_alert_metric)),
                ]"
              />
            </v-col>
            <!-- <v-col
              cols="6"
              sm="3"
            >
              <div class="d-flex align-center gap5 justify-space-between">
                <v-select
                  v-model="r.logical_operator"
                  dense
                  :items="['AND', 'OR']"
                  :label="
                    !!item.alert_conditions[i + 1] ? $t('logic_*') : $t('logic')
                  "
                  :rules="[
                    !!item.alert_conditions[i + 1] ? rules.required : true,
                    checkLogic(i),
                  ]"
                  clearable
                />
                <div class="d-flex justify-end">
                  <div
                    v-if="!item.alert_conditions[i + 1]"
                    class="action-btn btn-add"
                    @click="() => item.alert_conditions.push({})"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </div>
                  <div
                    v-if="item.alert_conditions.length > 1"
                    class="action-btn btn-delete"
                    @click="alertCondDelete(i)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </div>
                </div>
              </div>
            </v-col> -->
          </v-row>

          <!-- Condition -->
          <!-- Duration and Interval -->
          <span class="note-text">For How Long ?</span>
          <v-row>
            <v-col
              cols="6"
              sm="4"
            >
              <div class="d-flex align-baseline gap5">
                <v-select
                  v-model="item.condition_duration"
                  dense
                  :items="[
                    { id: 5, name: '5 mins' },
                    { id: 10, name: '10 mins' },
                    { id: 15, name: '15 mins' },
                  ]"
                  item-value="id"
                  item-text="name"
                  :label="$t('duration_*')"
                  :rules="[rules.required]"
                />
              </div>
            </v-col>
            <v-col
              cols="6"
              sm="4"
            >
              <v-select
                v-model="item.event_interval"
                dense
                :items="[
                  { id: 1, name: '1 Hour' },
                  { id: 3, name: '3 Hours' },
                  { id: 5, name: '5 Hours' },
                ]"
                item-value="id"
                item-text="name"
                :label="$t('event_interval_*')"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
          <!-- Duration and Interval -->
          <!-- Actions -->
          <alert-actions
            v-if="actions.length > 0"
            v-model="item.alert_actions"
            :type="type"
            :actions-list="actions"
          />

          <!-- Actions -->
        </v-container>
      </v-card-text>
      <v-card-actions>
        <small>{{ $t("indicates_required_field") }}</small>
        <v-spacer />
        <v-btn
          v-if="type == 'edit'"
          color="red darken-1"
          text
          @click="closeForm()"
        >
          Close
        </v-btn>
        <v-btn
          color="primary"
          text
          :loading="isLoading"
          @click="saveForm()"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>
<script>
import { validationRules } from "@/mixins/validationRules";
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  components: {
    AlertActions: () => import("./AlertActions.vue"),
  },
  mixins: [validationRules],
  props: {
    items: {
      type: Object,
      default() {
        return {};
      },
    },
    filterOptions: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      selectAllVehicles: false,
      type: "add_new",
      vehiclesCheckbox: [],
      item: {
        alert_conditions: [{}],
        alert_actions: [],
      },
      showError: false,
      timeOptions: [
        { id: "24/7", name: "24 hours" },
        { id: "custom", name: "Specific Range" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "alertsAndNotify/getLoading",
      metaData: "alertsAndNotify/getMetaData",
      //
      tenantLoading: "tenantsManagement/getIsLoading",
      tenantsList: "tenantsManagement/getTenantOptions",
      //
      vehicleLoading: "vehicles/getIsLoadingVehicles",
      vehicleList: "vehicles/getVehiclesList",
    }),
    isAllVehiclesSelected() {
      return (
        this.vehiclesCheckbox.length === this.vehicleList.length &&
        this.vehicleList.length > 0
      );
    },
    tenantsFiltersList() {
      // const arr = this.tenantsList.filter((r) => r.is_independent == "1");
      return this.tenantsList;
    },
    metrics() {
      if (this.item?.tenant || this.authUser.tenant_id) {
        const tRow = this.tenantsList?.find((r) => r.id == this.item?.tenant);
        const vt = tRow
          ? ["all", tRow.vehicle_tech]
          : ["all", this.authUser.user_tech];
        const mtr = this.metaData?.metrics;
        return mtr?.filter((r) => vt.includes(r.vehicle_type));
      } else return [];
    },
    operators() {
      return this.metaData?.operators;
    },
    operatorsBoolean() {
      const op = ["eq", "ne"];
      return this.metaData?.operators.filter((r) => op.includes(r.id));
    },
    actions() {
      let arr = this.metaData?.actions || [];

      return arr;
    },

  },
  watch: {
    items(v) {
      this.type = v?.id ? "edit" : "add_new";
      if (v.id) {
        this.showError = false;
        this.item = { ...this.items };
        // Pre-select vehicles in edit mode
        if (this.item.vehicles && this.item.vehicles.length > 0) {
          this.vehiclesCheckbox = this.item.vehicles.map((vehicle) => vehicle);
        } else {
          this.vehiclesCheckbox = [];
        }

        if (this.item?.alert_actions.length === 0) {
          // this.item.alert_actions.push({});
        }
      } else {
        this.$refs.form.reset();
        this.item = {
          alert_conditions: [{}],
          alert_actions: [],
        };
        this.vehiclesCheckbox = []; // Reset the vehicle selection in add mode
      }
    },
    "item.tenant": {
      handler: function (v) {
        if (v) {
          this.getVehicles(v);
        } else {
          if (this.authUser.isAdmin) {
            this.$store.commit("vehicles/SET_VEHICLES", []);
          }
        }
      },
      immediate: true,
    },
    vehiclesCheckbox: {
      handler() {
        const allMatch = this.vehicleList.every(vehicle =>
          this.vehiclesCheckbox.includes(vehicle.id)
        );
        if (this.vehiclesCheckbox.length === 0 || !allMatch) {
          this.selectAllVehicles = false;
        } else if (allMatch) {
          this.selectAllVehicles = true;
        }
        if (this.showError && this.vehiclesCheckbox.length === 0) {
          this.showError = true; // Trigger validation error
        }
      },
      immediate: true,
      deep: true,
    },
    vehicleList: {
      handler() {
        if (
          this.type === "edit" &&
          this.item.vehicles &&
          this.item.vehicles.length > 0
        ) {
          this.vehiclesCheckbox = this.item.vehicles.map((vehicle) => vehicle);
        } else {
          this.vehiclesCheckbox = [];
        }
      },
      immediate: true,
    },
    showError(newVal) {
      this.showError = newVal;
    }
  },
  mounted() {
    this.$store.commit("vehicles/SET_VEHICLES", []);
    if (!this.authUser.isAdmin) {
      this.getVehicles(this.authUser?.tenant_id);
    }
  },

  methods: {
    getConditionCheck() {
      return this.vehiclesCheckbox.some(id =>
        this.vehicleList.some(vehicle => vehicle.id === id)
      );
    },
    getRuleValidation() {
      if (this.type == 'edit') {
        const hasMatchingId = this.getConditionCheck()
        if (this.showError && !hasMatchingId) {
          return true;
        } else {
          return false;
        }
      } else if (this.item.tenant && this.showError) {
        if (this.vehiclesCheckbox.length > 0) {
          return false;
        }
        return true;
      }
      return false;
    },
    fieldSelected() {
      this.showError = true;
    },
    fieldSelectedVehicle() {
      const hasMatchingId = this.getConditionCheck();
      this.showError = (this.type === 'edit') ? !hasMatchingId : this.vehiclesCheckbox.length === 0;
    },
    deleteVehicle() {
      this.vehiclesCheckbox = [];
      this.showError = false;
    },
    toggleSelectAllVehicles() {
      if (this.selectAllVehicles) {
        this.vehiclesCheckbox = this.vehicleList.map((vehicle) => vehicle.id);
        this.showError = false;
      } else {
        this.vehiclesCheckbox = [];
        this.showError = true;
      }
    },
    async getVehicles(tenant_id) {
      await this.$store.dispatch("vehicles/vehiclesList", { tenant_id });
    },
    saveForm() {
      if (this.$refs.form.validate()) {
        const params = { ...this.item };
        const act = this.actions.find((r) => r.is_default);
        if (!params.alert_actions.length > 0) {
          params.alert_actions.push({ md_alert_action: act?.id });
        }
        // params.vehicles = params.vehicles.map((r) => ({ vehicle: r }));
        this.vehiclesCheckbox = this.vehiclesCheckbox.filter(id =>
          this.vehicleList.some(vehicle => vehicle.id === id)
        );
        params.vehicles = this.vehiclesCheckbox.map((vehicle) => ({ vehicle }));

        if (this.getConditionCheck()) {
          if (this.type == "add_new") {
            this.addNew(params);
          } else if (this.type == "edit") {
            this.editRow(params);
          }
        } else {
          this.showError = true
        }
      }
    },
    closeForm() {
      this.$emit("toggle");
    },
    async addNew(params) {
      await this.$store
        .dispatch("alertsAndNotify/add", params)
        .then(() => {
          this.$store.dispatch(
            "alerts/success",
            this.$t("created_successfully")
          );
          this.reloadList();
          this.closeForm();
        })
        .catch((error) => {
          this.$store.dispatch("alerts/error", error.response?.data?.message);
        });
    },
    async editRow(params) {
      await this.$store
        .dispatch("alertsAndNotify/update", params)
        .then(() => {
          this.$store.dispatch(
            "alerts/success",
            this.$t("updated_successfully")
          );
          this.reloadList();
          this.closeForm();
        })
        .catch((error) => {
          this.$store.dispatch("alerts/error", error.response?.data?.message);
        });
    },
    async deleteRow() {
      const id = this.item?.id;
      await this.$store
        .dispatch("alertsAndNotify/destory", { id })
        .then(() => {
          this.$store.dispatch(
            "alerts/success",
            this.$t("deleted_successfully")
          );
          this.$emit("delete");
          this.reloadList();
        })
        .catch((error) => {
          this.$store.dispatch("alerts/error", error.response?.data?.message);
        });
    },
    async reloadList() {
      await this.$store.dispatch("alertsAndNotify/list", this.filterOptions);
    },
    //
    getOperators(v) {
      if (v) {
        const logArr = this.metrics.find((r) => r.id == v);
        return logArr?.logical_op || [];
      } else return [];
    },
    onTimeChange(v) {
      if (v == "custom") {
        delete this.item.monitoring_start_time;
        delete this.item.monitoring_end_time;
      } else {
        this.item.monitoring_start_time = "00:00";
        this.item.monitoring_end_time = "23:59";
      }
    },
    alertCondDelete(val) {
      this.item.alert_conditions.splice(val, 1);
    },
    //
    getValueType(val) {
      if (val) {
        return this.metrics.find((r) => r.id == val);
      }
    },
    checkDataType(val) {
      if (val?.data_type == "numeric") {
        return (v) =>
          (v >= val.min && v <= val.max) || `Should be ${val.min} - ${val.max}`;
      } else if (val?.data_type == "boolean") {
        return (v) =>
          ["true", "True", "false", "False"].includes(v) ||
          `Should be True or False only`;
      } else {
        return true;
      }
    },
    checkTime(val) {
      if (val && this.item.monitoring_end_time) {
        var st = moment(val, "h:mma");
        var et = moment(this.item.monitoring_end_time, "h:mma");
        return st.isBefore(et) || "should be greater than Start Time";
      } else {
        return "start time required";
      }
    },
    checkLogic(i) {
      return (v) => {
        if (v && !this.item.alert_conditions[i + 1]) {
          return "add one more row";
        } else {
          return true;
        }
      };
    },
    checkByOperator(v, row) {
      return (r) => {
        if (v == "lt" && row) {
          return r != 0 || `should be greater than ${r}`;
        } else if (v == "gt" && row) {
          return r != row?.max || `should be less than ${r}`;
        } else {
          return true;
        }
      };
    },
  },
};
</script>
<style lang="sass" scoped>
.alert-form
  height: 95vh
  overflow-y: auto
:deep .note-text
    display: block
    text-wrap: nowrap
    font-size: 12px
    font-weight: 600
    margin-bottom: 0.5rem

.action-btn
  cursor: pointer

.btn-add
  &:hover
    i
      color: #20a39f

.btn-delete
  &:hover
    i
      color: red

.allCheckBox
  padding: 0 16px
  :deep .v-input__control
    .v-input__slot
      position: relative
      top: 8px
@media only screen and (max-width: 960px)
  .alert-form
    height: auto
// .alertFormContainer
//   width: 86%
//   margin:0
:deep .selectedBox
  display: inline-block
  width: 70%
  span
    text-overflow: ellipsis
    overflow: hidden
    white-space: nowrap
    display: inline-block
    width: 92%
</style>